import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.accelerantsCatalog
      }

    default:
      return state
  }
}

export function getAccelerantsCatalog () {
  return function (dispatch, getState, { api, schema }) {
    return api.getAccelerantsCatalog().then(function (response) {
      dispatch(addEntities({ accelerantsCatalog: response }))

      return response
    })
  }
}
