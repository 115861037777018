import { normalize } from 'normalizr'
import {
  ADD_ENTITIES,
  REMOVE_ENTITY,
  RESET_ENTITIES,
  addEntities,
  removeEntity,
  resetEntities
} from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.messageDrafts
      }

    case REMOVE_ENTITY:
      const { [action.payload]: _value, ...others } = state // eslint-disable-line

      return others

    case RESET_ENTITIES:
      if (!action.payload.messageDrafts) return state

      return {
        ...action.payload.messageDrafts
      }

    default:
      return state
  }
}

export function getClientMessageDrafts (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientMessageDrafts(clientID)
      .then(function (response) {
        const data = normalize(response, [schema.messageDraft])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getPendingMessageDrafts () {
  return function (dispatch, getState, { api, schema }) {
    return api.getPendingMessageDrafts()
      .then(function (response) {
        const data = normalize(response, [schema.messageDraft])

        dispatch(resetEntities({
          messageDrafts: {
            ...data.entities.messageDrafts
          }
        }))

        return response
      })
  }
}

export function createClientMessageDraft (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createClientMessageDraft(clientID, values)
      .then(function (response) {
        const data = normalize(response, schema.messageDraft)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function approveMessageRevision (messageDraftID, messageRevisionID) {
  return function (dispatch, getState, { api }) {
    return api.approveMessageRevision(messageRevisionID)
      .then(function (response) {
        const messageDraft = getState().messageDrafts[messageDraftID]
        const revisions = messageDraft.revisions.map(function (revision) {
          if (revision.id !== messageRevisionID) return revision

          return {
            ...revision,
            ...response
          }
        })
        const entities = {
          messageDrafts: {
            [messageDraftID]: {
              ...messageDraft,
              revisions
            }
          }
        }

        dispatch(addEntities(entities))

        return response
      })
  }
}

export function createMessageRevision (messageDraftID, values) {
  return function (dispatch, getState, { api }) {
    return api.createMessageRevision(messageDraftID, values)
      .then(function (response) {
        const messageDraft = getState().messageDrafts[messageDraftID]
        const entities = {
          messageDrafts: {
            [messageDraftID]: {
              ...messageDraft,
              revisions: [
                ...messageDraft.revisions,
                response
              ]
            }
          }
        }

        dispatch(addEntities(entities))

        return response
      })
  }
}

export function requestRevisionEdit (messageDraftID, revisionID, values) {
  return function (dispatch, getState, { api }) {
    return api.requestRevisionEdit(revisionID, values)
      .then(function (response) {
        const messageDraft = getState().messageDrafts[messageDraftID]
        const revisions = messageDraft.revisions.map(function (revision) {
          if (revision.id !== revisionID) return revision

          return {
            ...revision,
            ...response
          }
        })
        const entities = {
          messageDrafts: {
            [messageDraftID]: {
              ...messageDraft,
              revisions
            }
          }
        }

        dispatch(addEntities(entities))

        return response
      })
  }
}

export function markMessageDraftAsSent (messageDraftID) {
  return function (dispatch, getState, { api }) {
    return api.markMessageDraftAsSent(messageDraftID)
      .then(function (response) {
        dispatch(removeEntity(messageDraftID))

        return response
      })
  }
}

export function editMessageDraft (messageDraftID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.editMessageDraft(messageDraftID, values)
      .then(function (response) {
        const data = normalize(response, schema.messageDraft)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function deleteMessageDraft (messageDraftID) {
  return function (dispatch, getState, { api }) {
    return api.deleteMessageDraft(messageDraftID)
      .then(function (response) {
        dispatch(removeEntity(messageDraftID))

        return response
      })
  }
}
