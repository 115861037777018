import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.reports
      }

    default:
      return state
  }
}

export function getCalibrationReport (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getCalibrationReport(clientID).then(function (response) {
      const data = normalize(
        {
          id: clientID,
          calibration: response
        },
        schema.report
      )

      dispatch(addEntities(data.entities))

      return response
    })
  }
}
