import React from 'react'

export function useStorage () {
  const storeKey = function (key, value) {
    localStorage.setItem(key, value)
  }

  const fetchKey = function (key) {
    return localStorage.getItem(key)
  }

  const removeKey = function (key) {
    localStorage.removeItem(key)
  }

  return {
    storeKey,
    fetchKey,
    removeKey
  }
}

export default function Storage (Component) {
  return function (props) {
    return (
      <Component
        {...props}
        {...useStorage()}
      />
    )
  }
}
