import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.analysts
      }

    default:
      return state
  }
}

export function getAnalysts () {
  return function (dispatch, getState, { api, schema }) {
    return api.getAnalysts()
      .then(function (response) {
        const data = normalize(response, [schema.analyst])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function updateAnalyst (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateAnalyst(id, values)
      .then(function (response) {
        const data = normalize(response, schema.analyst)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
