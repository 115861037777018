import { normalize } from 'normalizr'
import { v4 as uuid } from 'uuid'
import {
  ADD_ENTITIES,
  REMOVE_ENTITY,
  removeEntity,
  addEntities
} from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.mealPlans
      }

    case REMOVE_ENTITY:
      const { [action.payload]: _value, ...others } = state // eslint-disable-line

      return others

    default:
      return state
  }
}

export function buildNewMealPlan (attrs) {
  return function (dispatch, getState, { api, schema }) {
    let goalType = attrs.goalType

    if (attrs.goalType === 'calibration') {
      goalType = 'lose_weight'
    }

    const mealPlan = {
      id: uuid(),
      newRecord: true,
      ...attrs,
      goalType: goalType
    }

    const data = normalize(mealPlan, schema.mealPlan)
    dispatch(addEntities(data.entities))

    return mealPlan
  }
}

export function removeMealPlan (id) {
  return function (dispatch, getState, { api }) {
    const mealPlan = getState().mealPlans[id]
    dispatch(removeEntity(id))
    return mealPlan
  }
}

export function getCurrentMealPlan (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getCurrentMealPlan(clientID).then(function (response) {
      if (!response) return

      const data = normalize(response, schema.mealPlan)
      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function updateMealPlan (values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateMealPlan(values).then(function (response) {
      if (!response) return

      const data = normalize(response, schema.mealPlan)
      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function createMealPlan (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createMealPlan(clientID, values).then(function (response) {
      if (!response) return

      const data = normalize(response, schema.mealPlan)
      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function getClientMealPlans (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientMealPlans(clientID).then(function (response) {
      const data = normalize(response, [schema.mealPlan])
      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function deleteMealPlan (id) {
  return function (dispatch, getState, { api }) {
    return api.deleteMealPlan(id).then(function (response) {
      dispatch(removeEntity(id))

      return response
    })
  }
}

export function upsertMealPlan (clientID, values) {
  return function (dispatch, getState, { api }) {
    const { newRecord, ...mealPlanValues } = values

    if (!newRecord) return dispatch(updateMealPlan(mealPlanValues))

    return dispatch(createMealPlan(clientID, mealPlanValues)).then(function (
      response
    ) {
      return dispatch(removeMealPlan(mealPlanValues.id))
    })
  }
}
