import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.chatMessages
      }

    default:
      return state
  }
}

export function getClientChatMessages (clientID, filters) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientChatMessages(clientID, filters)
      .then(function (response) {
        const data = normalize(response.chatMessages, [schema.chatMessage])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
