import { createStore, applyMiddleware, compose } from 'redux'
import { batchedSubscribe } from 'redux-batched-subscribe'
import { debounce } from 'lodash'
import thunk from 'redux-thunk'
import reducer from './reducer'
import api from '../api'
import enterpriseApi from '../api/enterprise'
import * as schema from '../api/schema'

let composeEnhancers = compose

if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionsBlacklist: []
  })
}

const enhancer = composeEnhancers(
  applyMiddleware(thunk.withExtraArgument({ api, enterpriseApi, schema })),
  batchedSubscribe(debounce(notify => notify()))
)

export default createStore(reducer, enhancer)
