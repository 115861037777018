import { normalize } from 'normalizr'
import { ADD_ENTITIES, REMOVE_ENTITY, addEntities, removeEntity } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.bodyCompositionReadings
      }

    case REMOVE_ENTITY:
      const { [action.payload]: _value, ...others } = state // eslint-disable-line

      return others

    default:
      return state
  }
}

export function getClientBodyCompositionReadings (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientBodyCompositionReadings(clientID)
      .then(function (response) {
        const data = normalize(response, [schema.bodyCompositionReading])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function deleteBodyCompositionReading (id) {
  return function (dispatch, getState, { api }) {
    return api.deleteBodyCompositionReading(id)
      .then(function (response) {
        dispatch(removeEntity(id))

        return response
      })
  }
}

export function createBodyCompositionReading (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createBodyCompositionReading(clientID, values)
      .then(function (response) {
        const data = normalize(response, schema.bodyCompositionReading)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
