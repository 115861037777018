import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

if (process.env.NEXT_PUBLIC_DD_SERVICE) {
  const applicationId = 'c9c7ddfb-2e75-40b0-a648-bd91eacb95ee'
  const clientToken = 'pub7fe6a09ba351e251b4371a0e3bbb46a4'
  const site = 'datadoghq.com'

  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: site,
    service: process.env.NEXT_PUBLIC_DD_SERVICE,
    env: process.env.NEXT_PUBLIC_DD_ENV,
    version: process.env.NEXT_PUBLIC_DD_VERSION,
    sampleRate: 100,
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })

  datadogLogs.init({
    clientToken: clientToken,
    site: site,
    forwardErrorsToLogs: true,
    sampleRate: 100
  })
}
