import { ADD_ENTITIES } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.chatSlaStats
      }

    default:
      return state
  }
}
