import camelize from 'camelize'
import {
  ADD_ENTITIES,
  REMOVE_ENTITY,
  addEntities,
  removeEntity
} from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.journal
      }

    case REMOVE_ENTITY: {
      const { date, id, type } = action.payload
      if (!date) return state

      const camelizedType = camelize(type)
      const reports = (state[date] || {})[camelizedType] || []

      return {
        ...state,
        [date]: {
          ...state[date],
          [camelizedType]: reports.filter(r => r.id !== id)
        }
      }
    }

    default:
      return state
  }
}

export function getClientJournal (clientID, params = {}) {
  return function (dispatch, getState, { api }) {
    return api.getClientJournal(clientID, params).then(function (response) {
      if (params.format === 'csv') return response

      dispatch(addEntities(response))

      return response
    })
  }
}

export function addDailyReport (clientID, values = {}) {
  return function (dispatch, getState, { api }) {
    return api.addDailyReport(clientID, values).then(function (response) {
      const state = getState().clientJournal
      const type = camelize(response.type)
      const journal = {
        [response.date]: {
          ...state[response.date],
          [type]: ((state[response.date] || {})[type] || []).concat([response])
        }
      }

      dispatch(addEntities({ journal }))

      return response
    })
  }
}

export function removeDailyReport (dailyReport) {
  return function (dispatch, getState, { api }) {
    return api.removeDailyReport(dailyReport.id).then(function (response) {
      dispatch(removeEntity(dailyReport))

      return response
    })
  }
}
