import { normalize } from 'normalizr'
import { ADD_ENTITIES, RESET_ENTITIES, addEntities, resetEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.clients
      }

    case RESET_ENTITIES:
      if (!action.payload.clients) return state

      return {
        ...action.payload.clients
      }

    default:
      return state
  }
}

export function getClients (params) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClients(params)
      .then(function (response) {
        const data = normalize(response.clients, [schema.client])

        if (params.page === 1) {
          dispatch(resetEntities({
            clients: {
              ...data.entities.clients
            }
          }))
        } else {
          dispatch(addEntities(data.entities))
        }

        return response
      })
  }
}

export function getClient (id, params = {}) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClient(id, params)
      .then(function (response) {
        const data = normalize(response, schema.client)
        dispatch(addEntities(data.entities))
        return response
      })
  }
}

export function searchClients (query, params = {}) {
  return function (dispatch, getState, { api, schema }) {
    return api.searchClients(query, params)
      .then(function (response) {
        const data = normalize(response, [schema.client])
        dispatch(addEntities(data.entities))
        return response
      })
  }
}

export function updateClient (id, values, allowMute) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateClient(id, values, allowMute)
      .then(function (response) {
        const data = normalize(response, schema.client)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function updateClientOnboarding (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateClientOnboarding(id, values)
      .then(function (response) {
        const data = normalize(response, schema.client)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function updateClientAlarms (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateClientAlarms(id, values)
      .then(function (response) {
        const data = normalize(response, schema.client)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function lockClients (ids) {
  return function (dispatch, getState, { api, schema }) {
    return api.lockClients(ids)
      .then(function (response) {
        const data = normalize(response.clients, [schema.client])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function unlockClients (ids) {
  return function (dispatch, getState, { api, schema }) {
    return api.unlockClients(ids)
      .then(function (response) {
        const data = normalize(response.clients, [schema.client])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function updateOrientationAttendance (attendance) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateOrientationAttendance(attendance)
      .then(function (response) {
        const data = normalize(response.clients, [schema.client])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
