import { normalize } from 'normalizr'
import {
  ADD_ENTITIES,
  REMOVE_ENTITY,
  RESET_ENTITIES,
  addEntities,
  removeEntity,
  resetEntities
} from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.tasks
      }

    case REMOVE_ENTITY:
      const { [action.payload]: _value, ...others } = state // eslint-disable-line

      return others

    case RESET_ENTITIES:
      if (!action.payload.tasks) return state

      return {
        ...action.payload.tasks
      }

    default:
      return state
  }
}

export function getClientTasks (clientID, params) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientTasks(clientID, params).then(function (response) {
      const data = normalize(response.tasks, [schema.task])
      dispatch(addEntities(data.entities))
      return response
    })
  }
}

export function markTaskAsCompleted (taskID) {
  return function (dispatch, getState, { api, schema }) {
    return api.markTaskAsCompleted(taskID).then(function (response) {
      const data = normalize(response, schema.task)
      dispatch(addEntities(data.entities))
      return response
    })
  }
}

export function markTaskAsInvalid (taskID, explanation) {
  return function (dispatch, getState, { api, schema }) {
    return api.markTaskAsInvalid(taskID, explanation).then(function (response) {
      const data = normalize(response, schema.task)
      dispatch(addEntities(data.entities))
      return response
    })
  }
}

export function createTask (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createTask(clientID, values).then(function (response) {
      const data = normalize(response, schema.task)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function updateTask (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateTask(id, values).then(function (response) {
      const data = normalize(response, schema.task)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function createTaskAction (taskID, values) {
  return function (dispatch, getState, { api }) {
    return api.createTaskActions(taskID, [values]).then(function (response) {
      const task = getState().tasks[taskID]
      const entities = {
        tasks: {
          [taskID]: {
            ...task,
            lastActedAt: new Date(),
            actions: [...task.actions, ...response]
          }
        }
      }

      dispatch(addEntities(entities))

      return response
    })
  }
}

export function deleteTask (id) {
  return function (dispatch, getState, { api }) {
    return api.deleteTask(id).then(function (response) {
      dispatch(removeEntity(id))

      return response
    })
  }
}

export function escalateTaskToDevice (taskID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.escalateTaskToDevice(taskID, values).then(function (response) {
      const data = normalize(response, schema.task)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function resetFilteredTasks () {
  return function (dispatch) {
    dispatch(resetEntities({ tasks: [] }))
  }
}

export function resolveMemberOpenTasks (memberID) {
  return function (dispatch, getState, { enterpriseApi, schema }) {
    return enterpriseApi
      .resolveMemberOpenTasks(memberID)
      .then(function (response) {
        dispatch(resetEntities({ tasks: [] }))

        return response
      })
  }
}
