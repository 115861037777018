import '../lib/datadog'
import { ApolloProvider } from '@apollo/client'
import { Provider } from 'react-redux'
import ErrorBoundary from '../components/error-boundary'
import store from '../lib/redux/store'
import graphql from '../lib/api/graphql'
import 'react-datepicker/dist/react-datepicker.css'
import 'quill/dist/quill.snow.css'
import '../styles/global.scss'
import '../styles/search-bar.scss'
import '../styles/client-status.scss'
import '../styles/chat-popup.scss'
import '../styles/chart.scss'
import '../styles/loading.scss'
import '../styles/weight-graph.scss'

export default function App ({ Component, pageProps }) {
  return (
    <ErrorBoundary>
      <ApolloProvider client={graphql}>
        <Provider store={store}>
          <Component {...pageProps} />
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}
