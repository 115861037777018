import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities, RESET_ENTITIES } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.calendarEvents
      }

    case RESET_ENTITIES:
      if (!action.payload.calendarEvents) return state

      return {
        ...action.payload.calendarEvents
      }

    default:
      return state
  }
}

export function getClientCalendarEvents (clientID, params) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientCalendarEvents(clientID, params)
      .then(function (response) {
        const data = normalize(response.events, [schema.calendarEvent])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getCalendarEvent (calendarEventID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getCalendarEvent(calendarEventID)
      .then(function (response) {
        const data = normalize(response, schema.calendarEvent)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getAnalystEvents (calendarId, params) {
  return function (dispatch, getState, { api, schema }) {
    return api.getAnalystEvents(calendarId, params)
      .then(function (response) {
        const data = normalize(response, [schema.calendarEvent])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getGroupCalendarsEvents (params) {
  return function (dispatch, getState, { api, schema }) {
    return api.getGroupCalendarsEvents(params)
      .then(function (response) {
        const data = normalize(response, [schema.calendarEvent])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function createCalendarEvent (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createCalendarEvent(clientID, values)
      .then(function (response) {
        const data = normalize(response, schema.calendarEvent)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function updateCalendarEvent (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateCalendarEvent(id, values)
      .then(function (response) {
        const data = normalize(response, schema.calendarEvent)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function cancelCalendarEvent (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.cancelCalendarEvent(id)
      .then(function (response) {
        const data = normalize(response, schema.calendarEvent)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function closeEventConference (eventId) {
  return function (dispatch, getState, { api, schema }) {
    return api.closeEventConference(eventId)
      .then(function (response) {
        const data = normalize(response, schema.calendarEvent)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function openEventConference (eventId) {
  return function (dispatch, getState, { api, schema }) {
    return api.openEventConference(eventId)
      .then(function (response) {
        const data = normalize(response, schema.calendarEvent)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function createGroupCalendarEvent (calendarID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createGroupCalendarEvent(calendarID, values)
      .then(function (response) {
        const data = normalize(response, schema.calendarEvent)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function updateGroupSession (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateGroupSession(id, values)
      .then(function (response) {
        const data = normalize(response, schema.calendarEvent)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
