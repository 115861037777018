export const UI = 'UI'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case UI:
      return {
        ...state,
        ...action.payload.update
      }

    default:
      return state
  }
}

export function updateUI (update) {
  return {
    type: UI,
    payload: { update }
  }
}
