import { reduce } from 'lodash'
import { formatTime } from '@/lib/functions'
import {
  ADD_ENTITIES,
  RESET_ENTITIES,
  addEntities,
  resetEntities
} from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.dataPointsJournal
      }
    case RESET_ENTITIES:
      if (!action.payload.dataPointsJournal) return state

      return {
        ...action.payload.dataPointsJournal
      }

    default:
      return state
  }
}

function normalizeDataPoints (records) {
  const newDataPoints = reduce(
    records,
    (data, record) => {
      const date = formatTime(record.capturedAt, 'YYYY-MM-DD')
      data[date] ||= {}
      data[date][record.attribute] ||= []
      data[date][record.attribute].push(record)

      return data
    },
    {}
  )

  return { dataPointsJournal: newDataPoints }
}

export function getMemberDataPoints (clientID, params = {}) {
  return function (dispatch, getState, { enterpriseApi }) {
    return enterpriseApi
      .getDataPoints(clientID, params)
      .then(function (response) {
        const data = normalizeDataPoints(response.dataPoints)

        if (params.page === 1) {
          dispatch(resetEntities(data))
        } else {
          dispatch(addEntities(data))
        }

        return response
      })
  }
}
