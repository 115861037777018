import { normalize } from 'normalizr'
import {
  ADD_ENTITIES,
  REMOVE_ENTITY,
  RESET_ENTITIES,
  addEntities,
  removeEntity,
  resetEntities
} from './actions'

const COACHING_COMPLETE = 'COACHING_COMPLETE'

const INITIAL_STATE = {
  coachingMembers: {},
  coachingCounter: 0
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.coachingMembers
      }
    case REMOVE_ENTITY:
      const { [action.payload]: _value, ...others } = state // eslint-disable-line

      return others
    case RESET_ENTITIES:
      if (!action.payload.coachingMembers) return state

      return {
        ...state,
        ...action.payload.coachingMembers
      }
    case COACHING_COMPLETE:
      return {
        ...state,
        coachingCounter: state.coachingCounter + 1
      }
    default:
      return state
  }
}

function coachingComplete () {
  return {
    type: COACHING_COMPLETE
  }
}

export function getCoachingMembers (params = {}) {
  return function (dispatch, getState, { enterpriseApi, schema }) {
    return enterpriseApi.getCoachingMembers(params).then(function (response) {
      const data = normalize(response.coachingMembers, [schema.coachingMembers])

      if (params.page === 1) {
        dispatch(
          resetEntities({
            coachingMembers: {
              ...data.entities.coachingMembers
            }
          })
        )
      } else {
        dispatch(addEntities(data.entities))
      }

      return response
    })
  }
}

export function completeMemberCoaching (memberId) {
  return function (dispatch, getState, { enterpriseApi, schema }) {
    return enterpriseApi
      .completeMemberCoaching(memberId)
      .then(function (response) {
        const data = normalize(response.coachingMember, [
          schema.coachingMembers
        ])

        dispatch(removeEntity(memberId))
        dispatch(addEntities(data.entities))
        dispatch(coachingComplete())

        return response
      })
  }
}

export function snoozeMemberCoaching (memberId) {
  return function (dispatch, getState, { enterpriseApi, schema }) {
    return enterpriseApi
      .snoozeMemberCoaching(memberId)
      .then(function (response) {
        const data = normalize(response.coachingMember, [
          schema.coachingMembers
        ])

        dispatch(removeEntity(memberId))
        dispatch(addEntities(data.entities))
        dispatch(coachingComplete())

        return response
      })
  }
}
