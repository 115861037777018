import Promise from 'bluebird'
import camelize from 'camelize'
import snakeize from 'snakeize'
import axios from 'axios'
import { toParams, authorizationHeaders, localTimeZone } from '../functions'
import { TASK_STATUSES } from '../constants'
import {
  API_SERVICE_URL,
  SHARED_API_GATEWAY_URL,
  DEVICE_SHARED_API_URL
} from '../env'

function buildAPIClient (baseURL) {
  const client = axios.create({
    baseURL,
    withCredentials: true
  })

  const etags = {}

  client.interceptors.request.use(
    function (config) {
      config.data = snakeize(config.data)
      config.headers = { ...config.headers, ...authorizationHeaders() }

      configureUrl(config)

      const urlRequested = config.baseURL + config.url
      if (config.method === 'get' && etags[urlRequested]) {
        config.headers['If-None-Match'] = etags[urlRequested]
      }

      config.headers['X-Time-Zone'] = localTimeZone()

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  client.interceptors.response.use(
    function (response) {
      const acronyms = { id: 'ID', url: 'URL' }
      // Don't transform dates (YYYY-MM-DD) or UUIDs as keys.
      const skip = /([\w]{8}(-[\w]{4}){3}-[\w]{12})|(\d{4}-\d{1,2}-\d{1,2})/

      response.originalData = response.data
      response.data = camelize(response.data, { acronyms, skip })

      if (response.config.method === 'get' && response.headers.etag) {
        etags[response.config.url] = response.headers.etag
      }

      return response
    },
    function (error) {
      if (error?.response?.status === 401) {
        window.location = '/logout?redirect=' + location.href
      }

      return Promise.reject(error)
    }
  )

  return client
}

function configureUrl (config) {
  let tenantUrl

  if (/\/program-portal$/.test(config.baseURL)) {
    tenantUrl = SHARED_API_GATEWAY_URL
    config.baseURL = tenantUrl + '/program-portal'
  }
  if (/\/device-portal$/.test(config.baseURL)) {
    tenantUrl = DEVICE_SHARED_API_URL
    config.baseURL = tenantUrl + '/device-portal'
  }

  return config
}

const programPortal = buildAPIClient(`${API_SERVICE_URL}/program-portal`)
const devicePortal = buildAPIClient(`${API_SERVICE_URL}/device-portal`)
const api = buildAPIClient(API_SERVICE_URL)

export default {
  getClients (params) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal.get(`/clients${qs}`).then(function (response) {
      return response.data
    })
  },

  getClient (id, params = {}) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal.get(`/clients/${id}${qs}`).then(function (response) {
      return response.data.client
    })
  },

  searchClients (query, params = {}) {
    const qs = `${toParams({
      ...params,
      query
    })}`

    return programPortal.get(`/clients/search?${qs}`).then(function (response) {
      return response.data.clients
    })
  },

  updateClient (id, values, allowMute = true) {
    return programPortal.patch(`/clients/${id}`, values).then(response => {
      if (
        allowMute &&
        Object.prototype.hasOwnProperty.call(values, 'muteTriggers')
      ) {
        return this.toggleMuteClient(id, values.muteTriggers)
      }

      return response.data.client
    })
  },

  updateClientOnboarding (id, values) {
    return programPortal
      .patch(`/clients/${id}/onboarding`, values)
      .then(response => {
        return response.data.client
      })
  },

  updateClientAlarms (id, values) {
    return programPortal
      .patch(`/clients/${id}/alarms`, values)
      .then(response => {
        return response.data.client
      })
  },

  lockClients (clientIds) {
    return programPortal
      .post('/clients/lock', { client_ids: clientIds })
      .then(function (response) {
        return response.data
      })
  },

  unlockClients (clientIds) {
    return programPortal
      .post('/clients/unlock', { client_ids: clientIds })
      .then(function (response) {
        return response.data
      })
  },

  updateOrientationAttendance (attendance) {
    return programPortal
      .post('/clients/onboarding/attendance', attendance)
      .then(function (response) {
        return response.data
      })
  },

  getClientNotes (id) {
    return programPortal.get(`/clients/${id}/notes`).then(function (response) {
      return response.data.notes
    })
  },

  updateClientNotes (id, notes) {
    return programPortal
      .patch(`/clients/${id}/notes`, { notes })
      .then(function (response) {
        return response.data.notes
      })
  },

  toggleMuteClient (id, muted) {
    return programPortal
      .patch(`/clients/${id}/toggle-mute`, { muted })
      .then(function (response) {
        return response.data.client
      })
  },

  getClientPrograms (clientID) {
    return programPortal
      .get(`/clients/${clientID}/programs`)
      .then(function (response) {
        return response.data.programs
      })
  },

  createProgram (id, values) {
    return programPortal
      .post(`/clients/${id}/programs`, values)
      .then(function (response) {
        return response.data.program
      })
  },

  deleteTask (id) {
    return programPortal.delete(`/tasks/${id}`)
  },

  getClientTasks (clientID, params = {}) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal
      .get(`/clients/${clientID}/tasks${qs}`)
      .then(function (response) {
        return response.data
      })
  },

  getClientAchievements (clientID, params = {}) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal
      .get(`/clients/${clientID}/achievements${qs}`)
      .then(function (response) {
        return response.data
      })
  },

  createTaskActions (taskID, actions) {
    return programPortal
      .post(`/tasks/${taskID}/actions`, { actions: actions })
      .then(function (response) {
        return response.data.taskActions
      })
  },

  getClientJournal (clientID, params) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal
      .get(`/clients/${clientID}/journal${qs}`)
      .then(function (response) {
        return response.data
      })
  },

  markTaskAsCompleted (taskID) {
    return this.updateTask(taskID, { status: TASK_STATUSES.COMPLETED })
  },

  markTaskAsInvalid (taskID, explanation) {
    return this.updateTask(taskID, {
      status: TASK_STATUSES.INVALID,
      explanation
    })
  },

  createTask (clientID, values) {
    return programPortal
      .post(`/clients/${clientID}/tasks`, values)
      .then(function (response) {
        return response.data.task
      })
  },

  updateTask (id, values) {
    return programPortal
      .patch(`/tasks/${id}`, values)
      .then(function (response) {
        return response.data.task
      })
  },

  escalateTaskToDevice (taskID, values) {
    return programPortal
      .post(`/tasks/${taskID}/escalate-device`, values)
      .then(function (response) {
        return response.data.task
      })
  },

  getAnalysts () {
    return programPortal.get('/analysts').then(function (response) {
      return response.data.analysts
    })
  },

  updateAnalyst (id, values) {
    return programPortal
      .patch(`/analysts/${id}`, values)
      .then(function (response) {
        return response.data.analyst
      })
  },

  verifyCurrentUser () {
    return api.post('/auth/verify').then(function (response) {
      return response.data.user
    })
  },

  loginUser (values) {
    return api.post('/auth/login', values)
  },

  logoutUser () {
    return api.post('/auth/logout')
  },

  verifyOTP (values) {
    return api.post('/auth/mfa/verify', values).then(function (response) {
      return response.data.token
    })
  },

  resetPassword (email) {
    return api.post('/passwords/reset', { email }).then(function (response) {
      return response.data
    })
  },

  getTriggers () {
    return programPortal.get('/triggers').then(function (response) {
      return response.data.triggers
    })
  },

  getClientChatMessages (clientID, filters) {
    const qs = filters ? `?${toParams(filters)}` : ''

    return programPortal
      .get(`/clients/${clientID}/chat_messages${qs}`)
      .then(function (response) {
        return response.data
      })
  },

  getClientTickets (clientID) {
    return programPortal
      .get(`/clients/${clientID}/tickets`)
      .then(function (response) {
        return response.data.tickets
      })
  },

  getTicket (id) {
    return programPortal.get(`/tickets/${id}`).then(function (response) {
      return response.data.ticket
    })
  },

  createTicket (clientID, values) {
    return programPortal
      .post(`/clients/${clientID}/tickets`, values)
      .then(function (response) {
        return response.data.ticket
      })
  },

  markTicketAsResolved (id) {
    return programPortal
      .put(`/tickets/${id}/resolve`)
      .then(function (response) {
        return response.data.ticket
      })
  },

  markTicketAsOpen (id) {
    return programPortal.put(`/tickets/${id}/open`).then(function (response) {
      return response.data.ticket
    })
  },

  createTicketMessage (clientID, ticketID, values) {
    return programPortal
      .post(`/clients/${clientID}/tickets/${ticketID}/messages`, values)
      .then(function (response) {
        return response.data.ticketMessage
      })
  },

  assignTicketAgent (id, agentID) {
    return programPortal
      .put(`/tickets/${id}/assign`, { agentID })
      .then(function (response) {
        return response.data.ticket
      })
  },

  getEventChannels () {
    return programPortal.get('/events/channels').then(function (response) {
      return response.data.channels
    })
  },

  getTicketUploadURL (room) {
    return api.get(`/api/chat/${room}/upload`).then(function (response) {
      return response.originalData
    })
  },

  uploadTicketImage (room, file) {
    return this.getTicketUploadURL(room).then(function (response) {
      const data = new FormData()
      const { fields, url } = response

      Object.keys(fields).forEach(function (field) {
        data.append(field, fields[field])
      })
      data.append('file', file)

      return axios.post(url, data).then(function () {
        return fields.key
      })
    })
  },

  fetchChatImage (url) {
    return api.get(url).then(function (response) {
      return response.data.url
    })
  },

  getCalibrationReport (clientID) {
    return programPortal
      .get(`/clients/${clientID}/reports/calibration`)
      .then(function (response) {
        return response.data
      })
  },

  getWeeklyMetrics (values) {
    const qs = toParams(values)

    return programPortal.get(`/metrics/weekly?${qs}`).then(function (response) {
      return response.data.metrics
    })
  },

  getWeeklyAnalystsMetrics (values) {
    const qs = toParams(values)

    return programPortal
      .get(`/analysts/metrics/weekly?${qs}`)
      .then(function (response) {
        return response.data.metrics
      })
  },

  getCallToken () {
    return api.get('/api/voice/token').then(function (response) {
      return response.data.token
    })
  },

  getClientMessageDrafts (clientID) {
    return programPortal
      .get(`/clients/${clientID}/messages/drafts`)
      .then(function (response) {
        return response.data.messageDrafts
      })
  },

  getPendingMessageDrafts () {
    return programPortal
      .get('/messages/drafts/pending')
      .then(function (response) {
        return response.data.messageDrafts
      })
  },

  createClientMessageDraft (clientID, values) {
    return programPortal
      .post(`/clients/${clientID}/messages/drafts`, values)
      .then(function (response) {
        return response.data.messageDraft
      })
  },

  markMessageDraftAsSent (messageDraftID) {
    return programPortal
      .put(`/messages/drafts/${messageDraftID}/sent`)
      .then(function (response) {
        return response.data.messageDraft
      })
  },

  editMessageDraft (messageDraftID, values) {
    return programPortal
      .patch(`/messages/drafts/${messageDraftID}`, values)
      .then(function (response) {
        return response.data.messageDraft
      })
  },

  requestRevisionEdit (revisionID, values) {
    return programPortal
      .patch(`/messages/revisions/${revisionID}/edit`, values)
      .then(function (response) {
        return response.data.messageRevision
      })
  },

  createMessageRevision (messageDraftID, values) {
    return programPortal
      .post(`/messages/drafts/${messageDraftID}/revisions`, values)
      .then(function (response) {
        return response.data.messageRevision
      })
  },

  approveMessageRevision (messageRevisionID) {
    return programPortal
      .put(`/messages/revisions/${messageRevisionID}/approve`)
      .then(function (response) {
        return response.data.messageRevision
      })
  },

  deleteMessageDraft (messageDraftID) {
    return programPortal.delete(`/messages/drafts/${messageDraftID}`)
  },

  getAnalystEvents (id, params) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal
      .get(`/calendars/${id}/events${qs}`)
      .then(function (response) {
        return response.data.events
      })
  },

  getGroupCalendarsEvents (params) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal
      .get(`/calendars/group-events${qs}`)
      .then(function (response) {
        return response.data.events
      })
  },

  getCalendarAvailability (id) {
    return programPortal
      .get(`/calendars/${id}/availability`)
      .then(function (response) {
        return response.data.calendar
      })
  },

  setCalendarAvailability (id, values) {
    return programPortal
      .put(`/calendars/${id}/availability`, values)
      .then(function (response) {
        return response.data.calendar
      })
  },

  getGroupCalendars () {
    return programPortal.get('/calendars/group').then(function (response) {
      return response.data.calendars
    })
  },

  getCalendarEvent (calendarEventID) {
    return programPortal
      .get(`/calendar/events/${calendarEventID}`)
      .then(function (response) {
        return response.data.event
      })
  },

  getClientCalendarEvents (clientID, params) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal
      .get(`/clients/${clientID}/calendar/events/upcoming${qs}`)
      .then(function (response) {
        return response.data
      })
  },

  createCalendarEvent (clientID, values) {
    return programPortal
      .post(`/clients/${clientID}/calendar/events`, values)
      .then(function (response) {
        return response.data.event
      })
  },

  createGroupCalendarEvent (calendarID, values) {
    return programPortal
      .post(`/calendars/${calendarID}/events`, values)
      .then(function (response) {
        return response.data.event
      })
  },

  updateGroupSession (id, values) {
    return programPortal
      .patch(`/calendars/group-sessions/${id}`, values)
      .then(function (response) {
        return response.data.event
      })
  },

  updateCalendarEvent (id, values) {
    return programPortal
      .patch(`/calendar/events/${id}`, values)
      .then(function (response) {
        return response.data.event
      })
  },

  cancelCalendarEvent (id) {
    return programPortal
      .post(`/calendar/events/${id}/cancel`)
      .then(function (response) {
        return response.data.event
      })
  },

  getClientBodyCompositionReadings (id) {
    return programPortal
      .get(`/clients/${id}/body-composition-readings`)
      .then(function (response) {
        return response.data.readings
      })
  },

  deleteBodyCompositionReading (id) {
    return programPortal.delete(`/body-composition-readings/${id}`)
  },

  createBodyCompositionReading (clientID, values) {
    return programPortal
      .post(`/clients/${clientID}/body-composition-readings`, values)
      .then(function (response) {
        return response.data.reading
      })
  },

  openEventConference (eventId) {
    return programPortal
      .post(`/calendar/events/${eventId}/open-conference`)
      .then(function (response) {
        return response.data.event
      })
  },

  closeEventConference (eventId) {
    return programPortal
      .post(`/calendar/events/${eventId}/close-conference`)
      .then(function (response) {
        return response.data.event
      })
  },

  getTeams () {
    return programPortal.get('/teams').then(function (response) {
      return response.data.teams
    })
  },

  createTeam (values) {
    return programPortal.post('/teams', values).then(function (response) {
      return response.data.team
    })
  },

  updateTeam (id, values) {
    return programPortal
      .patch(`/teams/${id}`, values)
      .then(function (response) {
        return response.data.team
      })
  },

  deleteTeam (id) {
    return programPortal.delete(`/teams/${id}`)
  },

  addDailyReport (clientID, values) {
    return programPortal
      .post(`/clients/${clientID}/daily-reports`, values)
      .then(function (response) {
        return response.data.dailyReport
      })
  },

  removeDailyReport (id) {
    return programPortal.delete(`/daily-reports/${id}`)
  },

  getClientProgramReflections (clientID) {
    return programPortal
      .get(`/clients/${clientID}/program/reflections`)
      .then(function (response) {
        return response.data.reflections
      })
  },

  getProgramReflection (id) {
    return programPortal
      .get(`/program/reflections/${id}`)
      .then(function (response) {
        return response.data.reflection
      })
  },

  submitAnalystFeedback (clientID, values) {
    return programPortal
      .post(`/clients/${clientID}/program/reflections/feedback`, values)
      .then(function (response) {
        return response.data.reflection
      })
  },

  getAnalystFeedbackTemplate (clientID) {
    return programPortal
      .get(`/clients/${clientID}/program/reflections/feedback/message`)
      .then(function (response) {
        return response.data.reflection
      })
  },

  updateAnalystFeedback (id, values) {
    return programPortal
      .patch(`/program/reflections/${id}`, values)
      .then(function (response) {
        return response.data.reflection
      })
  },

  getAccessPolicies () {
    return api.get('/api/policies').then(function (response) {
      return response.data.policies
    })
  },

  getChatMessageTemplates (params) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal
      .get(`/message_templates${qs}`)
      .then(function (response) {
        return response.data
      })
  },

  getChatMessageTemplate (chatMessageTemplateId, params) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal
      .get(`/message_templates/${chatMessageTemplateId}${qs}`)
      .then(function (response) {
        return response.data.messageTemplate
      })
  },

  getParentAccounts () {
    return devicePortal.get('/enterprise/accounts').then(function (response) {
      return response.data.accounts
    })
  },

  getClientAddress (clientId) {
    return devicePortal.get(`/clients/${clientId}`).then(function (response) {
      return response.data.client.address
    })
  },

  getCurrentMealPlan (clientID) {
    return programPortal
      .get(`/clients/${clientID}/meal_plans/current`)
      .then(function (response) {
        return response.data.mealPlan
      })
      .catch(function (error) {
        if (error.response.status) {
          return null
        }
      })
  },

  getClientMealPlans (clientID) {
    return programPortal
      .get(`/clients/${clientID}/meal_plans`)
      .then(function (response) {
        return response.data.mealPlans
      })
  },

  createMealPlan (clientID, values) {
    return programPortal
      .post(`/clients/${clientID}/meal_plans`, { mealPlan: values })
      .then(function (response) {
        return response.data.mealPlan
      })
  },

  updateMealPlan (values) {
    return programPortal
      .patch('/meal_plans', { mealPlan: values })
      .then(function (response) {
        return response.data.mealPlan
      })
  },

  deleteMealPlan (id) {
    return programPortal.delete(`/meal_plans/${id}`)
  },

  getAccelerantsCatalog () {
    return programPortal
      .get('/meal_plan/customizations/accelerants')
      .then(function (response) {
        return response.data.accelerants
      })
  },

  getExerciseCatalog () {
    return programPortal
      .get('/meal_plan/customizations/exercise')
      .then(function (response) {
        return response.data.exercise
      })
  },

  getRoutineCatalog () {
    return programPortal
      .get('/meal_plan/customizations/routine')
      .then(function (response) {
        return response.data.routine
      })
  },

  getClientProgramInduction (clientID) {
    return programPortal
      .get(`/clients/${clientID}/program-induction`)
      .then(function (response) {
        return response.data.programInduction
      })
  },

  startClientCalibration (clientID, values) {
    return programPortal
      .post(`/clients/${clientID}/start-calibration`, values)
      .then(function (response) {
        return response.data.programInduction
      })
  },

  paginateRawReadings (clientID, params) {
    const qs = params ? `?${toParams(params)}` : ''

    return programPortal
      .get(`/clients/${clientID}/raw-readings${qs}`)
      .then(function (response) {
        return response.data
      })
  }
}
