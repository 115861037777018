import axios from 'axios'
import Promise from 'bluebird'
import camelize from 'camelize'
import snakeize from 'snakeize'
import { authorizationHeaders, localTimeZone } from '../functions'

function toCamelCase (data) {
  const acronyms = { id: 'ID', url: 'URL' }
  // Don't transform dates (YYYY-MM-DD) or UUIDs as keys.
  const skip = /([\w]{8}(-[\w]{4}){3}-[\w]{12})|(\d{4}-\d{1,2}-\d{1,2})/

  return camelize(data, { acronyms, skip })
}

function buildAPIClient (baseURL) {
  const client = axios.create({
    baseURL,
    withCredentials: true
  })

  const etags = {}

  client.interceptors.request.use(
    function (config) {
      config.data = snakeize(config.data)
      config.headers = { ...authorizationHeaders(), ...config.headers }

      const urlRequested = config.baseURL + config.url
      if (config.method === 'get' && etags[urlRequested]) {
        config.headers['If-None-Match'] = etags[urlRequested]
      }

      config.headers['X-Time-Zone'] = localTimeZone()

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  client.interceptors.response.use(
    function (response) {
      response.originalData = response.data
      response.data = toCamelCase(response.data)

      if (response.config.method === 'get' && response.headers.etag) {
        etags[response.config.url] = response.headers.etag
      }

      return response
    },
    function (error) {
      if (!error.response) {
        return error
      }

      if (error?.response?.status === 401) {
        window.location = '/logout?redirect=' + location.href
      }

      console.error(error)

      error.response.data = toCamelCase(error.response.data)
      return Promise.reject(error)
    }
  )

  return client
}

export default buildAPIClient
