export const AUTHENTICATE = 'AUTHENTICATE'

export default function reducer (state = null, action) {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...action.payload.user
      }

    default:
      return state
  }
}

export function authenticate (user) {
  return {
    type: AUTHENTICATE,
    payload: { user }
  }
}

export function logoutUser () {
  return function (dispatch, getstate, { api }) {
    return api.logoutUser()
      .then(function (response) {
        dispatch(authenticate(null))
      })
  }
}

export function loginUser (values) {
  return function (dispatch, getstate, { api }) {
    return api.loginUser(values)
  }
}

export function verifyOTP (values) {
  return function (dispatch, getstate, { api }) {
    return api.verifyOTP(values)
  }
}

export function verifyCurrentUser () {
  return function (dispatch, getstate, { api }) {
    return api.verifyCurrentUser()
  }
}

export function resetPassword (email) {
  return function (dispatch, getstate, { api }) {
    return api.resetPassword(email)
  }
}
