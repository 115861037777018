import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.tickets
      }

    default:
      return state
  }
}

export function getClientTickets (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientTickets(clientID)
      .then(function (response) {
        const data = normalize(response, [schema.ticket])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getTicket (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.getTicket(id)
      .then(function (response) {
        const data = normalize(response, schema.ticket)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function markTicketAsResolved (ticketID) {
  return function (dispatch, getState, { api, schema }) {
    return api.markTicketAsResolved(ticketID)
      .then(function (response) {
        const data = normalize(response, schema.ticket)
        dispatch(addEntities(data.entities))
        return response
      })
  }
}

export function markTicketAsOpen (ticketID) {
  return function (dispatch, getState, { api, schema }) {
    return api.markTicketAsOpen(ticketID)
      .then(function (response) {
        const data = normalize(response, schema.ticket)
        dispatch(addEntities(data.entities))
        return response
      })
  }
}

export function createTicket (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createTicket(clientID, values)
      .then(function (response) {
        const data = normalize(response, schema.ticket)
        dispatch(addEntities(data.entities))
        return response
      })
  }
}

export function createTicketMessage (clientID, ticketID, values) {
  return function (dispatch, getState, { api }) {
    return api.createTicketMessage(clientID, ticketID, values)
      .then(function (response) {
        const state = getState()
        const ticket = state.tickets[ticketID]
        const entities = {
          tickets: {
            [ticket.id]: {
              ...ticket,
              messages: [
                ...ticket.messages,
                response
              ]
            }
          },
          chatMessages: {
            ...state.chatMessages,
            [response.id]: { ...response }
          }
        }

        dispatch(addEntities(entities))

        return response
      })
  }
}

export function assignTicketAgent (ticketID, agentID) {
  return function (dispatch, getState, { api, schema }) {
    return api.assignTicketAgent(ticketID, agentID)
      .then(function (response) {
        const data = normalize(response, schema.ticket)
        dispatch(addEntities(data.entities))
        return response
      })
  }
}
