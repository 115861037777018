import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.calendars
      }

    default:
      return state
  }
}

export function getCalendarAvailability (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.getCalendarAvailability(id)
      .then(function (response) {
        const data = normalize(response, schema.calendar)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function setCalendarAvailability (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.setCalendarAvailability(id, values)
      .then(function (response) {
        const data = normalize(response, schema.calendar)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getGroupCalendars () {
  return function (dispatch, getState, { api, schema }) {
    return api.getGroupCalendars()
      .then(function (response) {
        const data = normalize(response, [schema.calendar])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
