import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.programs
      }

    default:
      return state
  }
}

export function getClientPrograms (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientPrograms(clientID).then(function (response) {
      const data = normalize(response, [schema.program])
      dispatch(addEntities(data.entities))
      return response
    })
  }
}

export function createProgram (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createProgram(clientID, values).then(function (response) {
      const data = normalize(response, schema.program)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}
