import { toParams } from '../functions'
import { SHARED_API_GATEWAY_URL } from '../env'
import buildAPIClient from './build-client'

const enterpriseApi = buildAPIClient(`${SHARED_API_GATEWAY_URL}/enterprise-api`)

export default {
  getDataPoints (clientID, params) {
    const qs = params ? `?${toParams(params)}` : ''

    return enterpriseApi
      .get(`/members/${clientID}/data-points${qs}`)
      .then(function (response) {
        return response.data
      })
  },

  getCoachingMembers (params) {
    const qs = params ? `?${toParams(params)}` : ''

    return enterpriseApi
      .get(`/members/coaching${qs}`)
      .then(function (response) {
        return response.data
      })
  },

  completeMemberCoaching (memberId) {
    return enterpriseApi
      .post(`/members/${memberId}/coaching/complete`)
      .then(function (response) {
        return response.data
      })
  },

  snoozeMemberCoaching (memberId) {
    return enterpriseApi
      .post(`/members/${memberId}/coaching/snooze`)
      .then(function (response) {
        return response.data
      })
  },

  resolveMemberOpenTasks (memberId) {
    return enterpriseApi
      .post(`/members/${memberId}/coaching/resolve-tasks`)
      .then(function (response) {
        return response.data
      })
  },

  getAcetoneScoreBins (clientID, params) {
    const qs = params ? `?${toParams(params)}` : ''

    return enterpriseApi
      .get(`/members/${clientID}/acetone-score-bins${qs}`)
      .then(function (response) {
        return response.data
      })
  },

  createAcetoneScoreBin (clientID, params) {
    return enterpriseApi
      .post(`/members/${clientID}/acetone-score-bins`, params)
      .then(function (response) {
        return response.data
      })
  }
}
