import querystring from 'querystring'
import moment from 'moment-timezone'
import { chunk } from 'lodash'
import { ACCESS_TOKEN_KEY } from './constants'
import { useStorage } from '../components/storage'

const { fetchKey } = useStorage()

export function getParams () {
  const search = window.location.search.replace('?', '')
  return querystring.parse(search)
}

export function toParams (hash) {
  return querystring.stringify(hash)
}

export function formatAnalystName (analyst) {
  if (!analyst) return ''

  const { firstName, lastName } = analyst

  return `${firstName} ${lastName}`
}

export function formatPhoneNumber (str) {
  if (!str) return str

  const cleaned = str.replace(/[^+\d]/g, '')
  const match = cleaned.match(/^(.*)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    const intlCode = match[1] ? `${match[1]} ` : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return str
}

export function formatTime (date, format, timeZone = null) {
  if (!date) return date

  const momentDate = timeZone ? moment.tz(date, timeZone) : moment(date)

  return momentDate.format(format)
}

export function convertToTimeZone (date, timeZone) {
  if (!date) return

  return moment(date).tz(timeZone)
}

export function localTimeZone () {
  return moment.tz.guess()
}

export function parseDate (str, timeZone = null) {
  if (!str) return null

  const momentDate = timeZone ? moment.tz(str, timeZone) : moment(str)

  return momentDate.toDate()
}

export function dueDiff (due, unit = 'days') {
  const diff = moment.duration(moment(due).diff(new Date())).as(unit)
  return Math.round(diff)
}

export function timeDiff (from, to, unit) {
  return Math.abs(moment.duration(moment(from).diff(moment(to))).as(unit))
}

export function daysAgo (to) {
  const days = parseInt(Math.floor(timeDiff(new Date(), to, 'day'), 10))
  if (days === 0) return 'today'
  if (days === 1) return 'yesterday'

  return `${days} days ago`
}

export function isSameDay (date1, date2) {
  return moment(date1).isSame(date2, 'day')
}

export function isDateBetween (date, range) {
  return moment(date).isBetween(range[0], range[1])
}

export function isToday (date) {
  return isSameDay(date, new Date())
}

export function pastTime (date) {
  if (!date) return

  return moment(date).isBefore(new Date())
}

export function isTodayOrLater (date) {
  return isSameDateOrAfter(date, new Date())
}

export function isTodayOrBefore (date) {
  return moment(date).isSameOrBefore(new Date(), 'day')
}

export function isSameDateOrAfter (date, afterDate) {
  return moment(date).isSameOrAfter(afterDate, 'day')
}

export function subtractTime (from, amount, unit) {
  return moment(from).subtract(amount, unit).toDate()
}

export function addTime (from, amount, unit) {
  return moment(from).add(amount, unit).toDate()
}

export function weekNumber (date) {
  return moment(date).week()
}

export function weekDay (date) {
  return moment(date).weekday()
}

export function beginningOfTime (time, unit) {
  return moment(time).startOf(unit).toDate()
}

export function endOfTime (time, unit) {
  return moment(time).endOf(unit).toDate()
}

export function splitArray (array, parts = 2) {
  return chunk(array, Math.ceil(array.length / parts))
}

export function preventDefault (callback) {
  return function (ev) {
    ev.preventDefault()
    callback && callback()
  }
}

export function capitalize (str) {
  if (!str) return str

  const split = str.split('')
  const first = split[0].toUpperCase()

  return `${first}${split.slice(1).join('')}`
}

export function timeZones () {
  return moment.tz.names()
}

export function timeToPixels (timeStr, calendarConfig) {
  const time = new Date(timeStr)
  const hours = time.getHours()
  const mins = time.getMinutes() + hours * 60
  const diff = mins - calendarConfig.from

  return (diff / 60) * calendarConfig.hourHeight
}

export function b64DecodeUnicode (str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

export function fixedDecimal (number, decimals = 0) {
  if (!number) return number

  return parseFloat(number.toFixed(decimals), 10)
}

export function getBmi (heightIn, weightLb) {
  if (!heightIn || !weightLb) return

  const heightM = heightIn / 39.37
  const weightKg = weightLb / 2.205

  return fixedDecimal(weightKg / (heightM * heightM), 1)
}

export function authorizationHeaders () {
  const headers = { 'X-Portal-App': 'program' }
  const token = fetchKey(ACCESS_TOKEN_KEY)

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}
