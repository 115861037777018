export * from './clients'
export * from './tasks'
export * from './tickets'
export * from './client-journal'
export * from './client-journal-data-points'
export * from './current-user'
export * from './triggers'
export * from './chat-messages'
export * from './analysts'
export * from './programs'
export * from './reports'
export * from './message-drafts'
export * from './calendars'
export * from './calendar-events'
export * from './body-composition'
export * from './teams'
export * from './program-reflections'
export * from './ui'
export * from './access-policies'
export * from './meal-plans'
export * from './accelerants-catalog'
export * from './routine-catalog'
export * from './exercise-catalog'
export * from './program-inductions'
export * from './coaching-members'

export const ADD_ENTITIES = 'ADD_ENTITIES'
export const REMOVE_ENTITY = 'REMOVE_ENTITY'
export const RESET_ENTITIES = 'RESET_ENTITIES'

export function addEntities (entities) {
  return {
    type: ADD_ENTITIES,
    payload: entities
  }
}

export function removeEntity (id) {
  return {
    type: REMOVE_ENTITY,
    payload: id
  }
}

export function resetEntities (entities) {
  return {
    type: RESET_ENTITIES,
    payload: entities
  }
}
