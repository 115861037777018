import { onError } from '@apollo/client/link/error'
import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { authorizationHeaders } from '../../lib/functions'
import { SHARED_API_GATEWAY_URL } from '../../lib/env'

const httpLink = new HttpLink({
  uri: () => {
    const tenantUrl = SHARED_API_GATEWAY_URL
    return `${tenantUrl}/graphql`
  },
  credentials: 'include'
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...authorizationHeaders()
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError?.response?.status === 401) {
    window.location = '/logout?redirect=' + location.href
  }
})

export default new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})
