const { version } = require('../package.json')

module.exports = {
  API_SERVICE_URL: process.env.NEXT_PUBLIC_API_SERVICE_URL,
  SHARED_API_GATEWAY_URL: process.env.NEXT_PUBLIC_SHARED_API_GATEWAY_URL,
  DEVICE_SHARED_API_URL: process.env.NEXT_PUBLIC_DEVICE_SHARED_API_URL,
  APP_STAGE: process.env.NEXT_PUBLIC_APP_STAGE,
  APP_VERSION: version,
  BUGSNAG_KEY: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
  DEVICE_PORTAL_URL: process.env.NEXT_PUBLIC_DEVICE_PORTAL_URL,
  RESET_PASSWORD_URL: process.env.NEXT_PUBLIC_RESET_PASSWORD_URL,
  SHARED_REPORT_DASHBOARD_URL:
    process.env.NEXT_PUBLIC_SHARED_REPORT_DASHBOARD_URL
}
