import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.programInductions
      }

    default:
      return state
  }
}

export function getClientProgramInduction (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientProgramInduction(clientID).then(function (response) {
      const data = normalize(response, schema.programInduction)
      dispatch(addEntities(data.entities))
      return response
    })
  }
}

export function startClientCalibration (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api
      .startClientCalibration(clientID, values)
      .then(function (response) {
        const data = normalize(response, schema.programInduction)
        dispatch(addEntities(data.entities))
        return response
      })
  }
}
