export const ACCESS_TOKEN_KEY = '_ut'
export const TEMP_ACCESS_TOKEN_KEY = '_tut'

export const CALENDAR_COLORS = {
  personal: 'primary',
  group_sessions: 'success',
  webinar: 'warning',
  orientation: 'secondary'
}

export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const CALENDAR_TYPES = {
  personal: 'Personal appointments',
  group_sessions: 'Group sessions',
  webinar: 'Webinars',
  orientation: 'Orientation sessions'
}

export const CALENDAR_EVENT_CALENDAR_TYPES = {
  ORIENTATION: 'orientation',
  GROUP_SESSIONS: 'group_sessions',
  PERSONAL: 'personal'
}

export const CALENDAR_EVENT_TYPES = {
  'Profile Day': {
    title: 'Profile Day Call',
    description:
      "Let's review your progress during your first couple weeks on Invoy.",
    canCancel: false,
    canReschedule: true,
    addConference: true
  },
  Other: {
    title: '',
    description: '',
    canCancel: true,
    canReschedule: true,
    addConference: true
  }
}

export const TASK_STATUSES = {
  OPEN: 'open',
  COMPLETED: 'completed',
  INVALID: 'invalid',
  ESCALATED_TO_DEVICE: 'escalated_to_device'
}

export const PROGRAM_STATUSES = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  GRAY: 'gray'
}

export const SUBSCRIPTION_STATUSES = {
  registered: 'Registered',
  trialing: 'Trialing',
  active: 'Active',
  paused: 'Paused',
  canceled: 'Canceled'
}

export const PROGRAM_PHASES_NAMES = {
  calibration: 'Calibration',
  lose_weight: 'Weight Loss',
  maintain_weight: 'Weight Maintenance'
}

export const CALIBRATION_PROGRAM = 'calibration'

export const MEAL_PLAN_GOALS = {
  LOSE_WEIGHT: 'lose_weight',
  MAINTAIN_WEIGHT: 'maintain_weight'
}

export const MEAL_PLAN_PRINCIPLE_TYPE = {
  carbs_management: 'Carbohydrate Management',
  portion_control: 'Portion Control',
  intermittent_fasting: 'Intermittent Fasting'
}

export const PROGRAM_PLANS = [
  'Calibration',
  'Allergen Removal',
  'Low Carb',
  'Paleo',
  'Mediterranean',
  'FODMAP',
  'Smoothie and Small Meal',
  'Low-Glycemic Index',
  'Ornish',
  'Moderate Carb, Low Allergen',
  'Vegan',
  'Pescatarian'
]

export const PROGRAM_ACCELERANTS = [
  { id: 'exercise', title: 'Exercise' },
  { id: 'remove_artificial_sugars', title: 'Remove Artificial Sugars' },
  { id: 'limit_alcohol', title: 'Limit Alcohol' },
  { id: 'limit_dairy', title: 'Limit Dairy' },
  { id: 'decrease_protein', title: 'Decrease Protein' },
  { id: 'remove_allergens', title: 'Remove Allergens' },
  { id: 'longer_nighttime_fast', title: 'Longer Nighttime Fast' },
  { id: 'meditation', title: 'Meditation' },
  { id: 'single-meal_replacement', title: 'Single-Meal Replacement' },
  { id: 'caloric_restriction', title: 'Caloric Restriction' },
  { id: 'deep_breathing', title: 'Deep Breathing' },
  { id: 'daily_15-min_workout', title: 'Daily 15-min Workout' },
  { id: 'intermittent_fasting', title: 'Intermittent Fasting' },
  { id: 'juice_cleanse', title: 'Juice Cleanse' },
  { id: 'controlled_cheat_meal', title: 'Controlled Cheat Meal' },
  { id: 'limit_caffeine', title: 'Limit Caffeine' },
  { id: 'full-day_fast', title: 'Full-Day Fast' },
  { id: 'fat_fast', title: 'Fat Fast' },
  { id: 'smoothie_and_small_meal', title: 'Smoothie and Small Meal' },
  { id: 'micro-exercises', title: 'Micro-Exercises' },
  { id: 'remove_gluten', title: 'Remove Gluten' },
  { id: 'daily_hydration_(6-8_cups)', title: 'Daily Hydration (6-8 cups)' },
  { id: 'prescribed_supplements', title: 'Prescribed supplements' },
  { id: 'dietary_fiber', title: 'Dietary Fiber' },
  { id: 'limit_nuts', title: 'Limit Nuts' },
  { id: 'active_recovery', title: 'Active Recovery' },
  { id: 'remove_raw_foods', title: 'Remove Raw Foods' },
  { id: 'light_dinner', title: 'Light Dinner' }
]

export const PROGRAM_HABITS = [
  { id: 'drink_water_not_sugar', title: 'Drink water not sugar' },
  { id: 'snacks_to_work', title: 'Take snacks to work' },
  { id: 'snacks_in_car', title: 'Keep snacks in the car' },
  { id: 'coffee_tea_at_home', title: 'Make tea/coffee at home' },
  { id: 'eat_on_lunch_plates', title: 'Eat using lunch plates' },
  { id: 'water_before_eating', title: 'Drink water before eating' },
  { id: 'wait_60_before_eating', title: 'Wait 60 sec before eating' },
  { id: 'no_candy', title: 'No candy' },
  { id: 'no_starbucks', title: 'No Starbucks' },
  { id: 'no_soda', title: 'No soda (diet or otherwise)' },
  { id: 'no_food_delivery', title: 'No food delivery services' },
  { id: 'eat_at_table', title: 'Eat at dining table (not in bed)' },
  { id: 'brush_teeth_after_dinner', title: 'Brush teeth after dinner' },
  { id: 'follow_eating_curfew', title: 'Follow eating curfew' },
  { id: 'update_journal', title: 'Update journal' },
  {
    id: 'mantra_before_coming_home',
    title: 'Restate mantra before coming home'
  },
  { id: 'wall_pushups_5', title: 'Do 5 wall pushups after stress' },
  { id: 'jumping_jacks_5', title: 'Do 5 jumping jacks after stress' },
  { id: 'list_to_apple_watch', title: 'Listen to my Apple Watch' },
  { id: 'deep_breathing_for_5_min', title: 'Deep breathing for 5 min' },
  {
    id: 'electronics_off_before_bed',
    title: 'Turn off electronics 90 min before bed'
  },
  { id: '7_hours_of_sleep', title: 'Get 7+ hours of sleep' },
  { id: 'maintain_wakeup_time', title: 'Maintain wakeup time' },
  { id: 'maintain_bedtime', title: 'Maintain bedtime' },
  { id: 'take_morning_vitamins', title: 'Take morning vitamins' },
  { id: 'take_night_vitamins', title: 'Take night vitamins' },
  { id: 'drink_6_glasses_of_water', title: 'Drink 6 glasses of water' },
  { id: 'morning_meditation', title: 'Perform 10 minute AM meditation' },
  { id: 'evening_meditation', title: 'Perform 10 minute PM meditation' },
  { id: '10_min_of_sun', title: 'Get 10 min of sun daily' },
  { id: 'eat_at_home', title: 'Eat at home' },
  { id: 'toss_leftovers', title: 'Toss left overs' },
  { id: 'chew_gum', title: 'Chew Gum' },
  { id: 'no_lonely_carbs', title: 'No Lonely Carbs' },

  // Temporary food
  { id: '3_fistfuls_of_vegetables', title: '3 fistfuls of vegetables' },
  { id: 'protein_and_healthy_fats', title: 'Protein and healthy fats' },
  { id: '6_glasses_of_water', title: '6+ glasses of water' },
  { id: 'no_bread_rice_or_starches', title: 'No bread, rice, or starches' },
  { id: 'no_fruit', title: 'No fruit' },
  { id: 'no_sugary_drinks', title: 'No sugary drinks' }
]

export const CLIENT_ONBOARDING_PHASES = [
  { id: 'registered', column: 'createdAt', title: 'Registered', position: 0 },
  {
    id: 'orientation_scheduled',
    column: 'orientationScheduledAt',
    title: 'Orientation Scheduled',
    position: 1
  },
  {
    id: 'orientation_completed',
    column: 'orientationCompletedAt',
    title: 'Orientation Completed',
    position: 3
  },
  {
    id: 'device_shipped',
    column: 'deviceShippedAt',
    title: 'Device Shipped',
    position: 4
  },
  {
    id: 'device_delivered',
    column: 'deviceDeliveredAt',
    title: 'Device Delivered',
    position: 5
  },
  {
    id: 'one_practice_test_completed',
    column: 'practiceTestSentAt',
    title: 'Practice Test Completed',
    position: 6
  },
  {
    id: 'program_started',
    column: 'calibrationStartedAt',
    title: 'Program Started',
    position: 7
  }
]

export const MEMBER_COACHING_PAGINATION_LIMIT = 100
export const MEMBER_COACHING_COUNT_LIMIT = 20

export const MEMBER_COACHING_LISTS = [
  {
    title: 'Day 1-2',
    slug: 'day1-2',
    description: 'New members with high motivation but need guidance.',
    imageName: 'day_1'
  },
  {
    title: 'Day 3-10',
    slug: 'day3-10',
    description: 'New members with high motivation but need guidance.',
    imageName: 'day_3'
  },
  {
    title: 'Day 11-17',
    slug: 'day11-17',
    description: 'New members with high motivation but need guidance.',
    imageName: 'day_11'
  },
  {
    title: 'Day 18-45',
    slug: 'day18-45',
    description: 'New members with high motivation but need guidance.',
    imageName: 'day_18'
  },
  {
    title: 'Day 46+',
    slug: 'day46plus',
    description: 'New members with high motivation but need guidance.',
    imageName: 'day_46'
  }
]
