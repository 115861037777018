import { normalize } from 'normalizr'
import {
  ADD_ENTITIES,
  addEntities
} from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.programReflections
      }

    default:
      return state
  }
}

export function getClientProgramReflections (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientProgramReflections(clientID)
      .then(function (response) {
        const data = normalize(response, [schema.programReflection])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getProgramReflection (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.getProgramReflection(id)
      .then(function (response) {
        const data = normalize(response, schema.programReflection)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function submitAnalystFeedback (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.submitAnalystFeedback(clientID, values)
      .then(function (response) {
        const data = normalize(response, schema.programReflection)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getAnalystFeedbackTemplate (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getAnalystFeedbackTemplate(clientID)
      .then(function (response) {
        return response
      })
  }
}

export function updateAnalystFeedback (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateAnalystFeedback(id, values)
      .then(function (response) {
        const data = normalize(response, schema.programReflection)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
