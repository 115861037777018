import { combineReducers } from 'redux'
import clients from './clients'
import tasks from './tasks'
import clientJournal from './client-journal'
import currentUser from './current-user'
import analysts from './analysts'
import triggers from './triggers'
import programs from './programs'
import tickets from './tickets'
import chatMessages from './chat-messages'
import reports from './reports'
import messageDrafts from './message-drafts'
import calendars from './calendars'
import calendarEvents from './calendar-events'
import bodyComposition from './body-composition'
import teams from './teams'
import programReflections from './program-reflections'
import chatSlaStats from './chat-sla-stats'
import ui from './ui'
import accessPolicies from './access-policies'
import mealPlans from './meal-plans'
import accelerantsCatalog from './accelerants-catalog'
import routineCatalog from './routine-catalog'
import exerciseCatalog from './exercise-catalog'
import programInductions from './program-inductions'
import coachingMembers from './coaching-members'
import clientJournalDataPoints from './client-journal-data-points'

const rootReducer = combineReducers({
  clients,
  tasks,
  clientJournal,
  clientJournalDataPoints,
  currentUser,
  analysts,
  triggers,
  programs,
  tickets,
  chatMessages,
  reports,
  messageDrafts,
  calendars,
  calendarEvents,
  bodyComposition,
  teams,
  programReflections,
  chatSlaStats,
  ui,
  accessPolicies,
  mealPlans,
  accelerantsCatalog,
  routineCatalog,
  exerciseCatalog,
  programInductions,
  coachingMembers
})

export default rootReducer
